<ng-template #editPlaylistModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div
      class="modal-content sos-generic-modal-container sos-scrollbar-none"
      id="addTrackToPlaylistModal"
    >
      <div class="modal-body">
        <h3>Edit playlist details</h3>
        <div class="row text-left mt-0 forms-bottom-line">
          <form [formGroup]="editPlaylistForm">
            <div class="col-xs-12 pl-0 pr-0 flex mt-5">
              <div
                class="col-xs-5 col-lg-3 pr-0 mr-4 container-image-edit"
                (mouseenter)="hover = true && createImagePermission"
                (mouseleave)="hover = false"
                ngfDrop
                multiple="1"
                selectable="1"
                accept=".jpg,jpeg"
                (click)="setNullValue($event)"
                (fileChange)="onFileChange($event)"
                [fileDropDisabled]="!createImagePermission || imageLoading"
              >
                <img
                  *ngIf="thumbUrl"
                  [src]="thumbUrl"
                  alt="playlist"
                  class="background-card img-preview"
                />
                <div
                  *ngIf="!thumbUrl || hover"
                  class="background-card"
                  [ngClass]="{ 'hover-image': thumbUrl, 'default-image': !thumbUrl }"
                >
                  <div
                    class="flex flex-col items-center center-icon"
                    style="
                      position: absolute;
                      top: 50%;
                      transform: translate(-50%, -50%);
                      color: #cecece;
                      gap: 5px;
                      width: calc(100% - 15px);
                    "
                  >
                    <span
                      class="material-icons"
                      [attr.data-icon]="'image_search'"
                      style="font-size: 50px"
                    >
                    </span>
                    <span style="font-weight: 600"
                      >{{ createImagePermission ? 'Upload' : 'Select' }} Image</span
                    >
                  </div>
                </div>
                <i
                  *ngIf="imageLoading"
                  style="position: absolute; top: 70%; left: 33%"
                  class="spin blue-spin large loader-margin"
                ></i>
              </div>
              <div class="col-xs-7 col-lg-9 flex flex-col justify-center pl-0">
                <div class="flex items-end justify-between">
                  <label style="text-transform: none">Select a cover image</label>
                </div>

                <div
                  style="
                    display: grid;
                    grid-template-rows: repeat(2, 70px);
                    grid-template-columns: repeat(auto-fit, 70px);
                    grid-gap: 1px;
                    overflow: hidden;
                    height: 142px;
                  "
                >
                  <div *ngFor="let artist of artistImages">
                    <img
                      [src]="artist.profileImages?.thumbUrl"
                      [alt]="artist.name"
                      style="width: 70px; height: auto"
                      (click)="thumbUrl = artist.profileImages?.thumbUrl"
                      (error)="handleMissingImage($event)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12 mt-5">
              <app-input-field
                [control]="getControl('name')"
                [errors]="editPlaylistForm.controls['name'].errors"
                formControlName="name"
                label="Name"
                placeholder="Name"
                [required]="true"
              >
              </app-input-field>
              <app-text-area
                [control]="getControl('description')"
                [errors]="editPlaylistForm.controls['description'].errors"
                label="Description"
                placeholder="Description"
              >
              </app-text-area>
              <div *ngIf="isAllowedPrivacy && isMyPlaylist">
                <label for="privacy">Privacy </label>
                <select id="privacy" name="privacy" formControlName="privacy" class="w-full mb-5">
                  <option value="OnlyMe">Only me</option>
                  <option value="TeamView">Team View (Read-Only)</option>
                  <option value="TeamEdit">Team Edit (Edit by Team)</option>
                </select>
              </div>
              <div>
                <label for="status">Status </label>
                <select id="status" name="status" formControlName="status" class="w-full mb-5">
                  <option value="unpublished">Unpublished</option>
                  <option value="published">Published</option>
                </select>
              </div>
              <div *ngIf="isAllowEditorial">
                <label for="editorial">Editorial </label>
                <select
                  id="editorial"
                  name="editorial"
                  formControlName="editorial"
                  class="w-full mb-5"
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div class="flex justify-end mt-4" style="gap: 10px">
                <app-btn [classGroup]="'transparent'" (click)="hideModal()">Cancel</app-btn>
                <app-btn
                  [disabled]="loadingForm || editPlaylistForm.invalid"
                  (click)="submitEditPlaylist()"
                >
                  Save @if (loadingForm) {
                  <i class="spin medium loader-margin"></i>
                  }
                </app-btn>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
