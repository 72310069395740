import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NewPlaylistModalComponent } from 'src/app/angular/modules/modals/components/create-playlist-modal/new-playlist-modal.component';
import {
  PlaylistsStereoSpacesService,
  AuthenticationService,
  SearchService,
} from 'sostereo-services';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { clone, cloneDeep, debounce, map } from 'lodash-es';
import { playlistMenuTable } from './config/playlist-menu-table.config';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { PlayerWidgetService } from '../../../services/player-widget.service';
import { filter, finalize, takeUntil, throttleTime } from 'rxjs/operators';
import { CopyPlaylistModalComponent } from 'src/app/angular/modules/modals/components/copy-playlist-modal/copy-playlist-modal.component';
import { SearchTagsService } from 'src/app/angular/shared/services/search-tags.service';
import { PlayerService } from 'src/app/angular/shared/services/player.service';
import { SongStatusModalComponent } from 'src/app/angular/modules/modals/components/song-status-modal/song-status-modal.component';
import { PlaylistService } from 'src/app/angular/shared/services/playlist.service';
import { Location, NgFor, NgIf, NgClass, NgStyle } from '@angular/common';
import * as Sentry from '@sentry/browser';
import { GenericModalService } from 'src/app/angular/shared/services/generic-modal.service';
import { PlaylistMenuDetailComponent } from '../playlist-menu-detail/playlist-menu-detail.component';
import { SongStatusModalComponent as SongStatusModalComponent_1 } from '../../../../modules/modals/components/song-status-modal/song-status-modal.component';
import { CopyPlaylistModalComponent as CopyPlaylistModalComponent_1 } from '../../../../modules/modals/components/copy-playlist-modal/copy-playlist-modal.component';
import { NewPlaylistModalComponent as CreatePlaylistModalComponent_1 } from '../../../../modules/modals/components/create-playlist-modal/new-playlist-modal.component';
import { PlaylistsEditorialComponent } from '../playlists-editorial/playlists-editorial.component';
import { TableComponent } from '../../../../shared/components/table/table.component';
import { FormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-playlist-menu',
  templateUrl: './playlist-menu.component.html',
  styleUrls: ['./playlist-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
  standalone: true,
  imports: [
    MatMenu,
    NgFor,
    MatMenuItem,
    NgIf,
    NgClass,
    NgStyle,
    MatTooltip,
    FormsModule,
    MatMenuTrigger,
    TableComponent,
    PlaylistsEditorialComponent,
    PlaylistMenuDetailComponent,
    CreatePlaylistModalComponent_1,
    CopyPlaylistModalComponent_1,
    SongStatusModalComponent_1,
  ],
})
export class PlaylistMenuComponent implements OnInit, OnDestroy, OnChanges {
  @Input() draggedRow: any;
  @Input() selectedPlaylistId: string;
  @Input() selectedPlayDetail: object;
  @Input() searchView: boolean = false;
  @Input() editorialSearchParam: string;
  @Input() goToEditorial: boolean;
  @Output() playlistSelected = new EventEmitter<string>();
  @Output() editPlaylist = new EventEmitter<any>();
  @Output() deleteEditorialParam = new EventEmitter<any>();
  @ViewChild('newPlaylistModalComponent', { static: true })
  newPlaylistModal: NewPlaylistModalComponent;

  @ViewChild('copyPlaylistModal', { static: true }) copyPlaylistModal: CopyPlaylistModalComponent;
  @ViewChild('songStatusModalComponent', { static: true })
  private songStatusModal: SongStatusModalComponent;

  @ViewChild('playlistDetail', { static: false }) playlistDetail: PlaylistMenuDetailComponent;

  public tableTabs = [];
  public loading = true;
  public displayTeamPlaylist = false;
  playlistsCount: BehaviorSubject<number> = this.commonService.playlistsCount;
  private heightSection = 130;
  public windowHeight = window.innerHeight - this.heightSection;
  private sessionSettings = null;
  public inputValue = '';
  public currentUser = this.authenticationService.getCurrentUser();
  public editorial = true;
  public navigationPlaylistId;

  private tagAdded$;
  private tagRemoved$;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  private updatePlaylist$: Subscription;
  private updatePlaylistFromData$: Subscription;
  private updatePlaylistMenuCollapse$: Subscription;
  public ignoreTags = false;
  public openSearch = false;
  public filterSong;
  private routerSubscribe$: Subscription[] = [];
  public isAdmin = this.commonService.isAllowed(['*', 'ListTiers']);
  public oldParams: any = {};
  public noResults = false;
  public currentGenericTags = '';
  public displayHideOption = false;
  private genericTagUpdated$: Subscription;
  public plLibrary = window.location.pathname.includes('/edit-playlist');
  public hideTags = this.plLibrary;
  public showBackOption =
    this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();

  public hideOptionToCollapse = this.plLibrary;
  public currentUrl = this.router.url;
  public slugOrId;
  public plTypingType = null;
  public plFilterLabel;
  public plTypingOptions = cloneDeep(this.playlistService.plTypingOptions);
  public showClosePlsMenu = false;

  constructor(
    private playlistsService: PlaylistsStereoSpacesService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private trackingService: TrackingService,
    private router: Router,
    public deviceDetectorService: DeviceDetectorService,
    public playerWidgetService: PlayerWidgetService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private searchService: SearchService,
    public searchTagsService: SearchTagsService,
    private playerService: PlayerService,
    private playlistService: PlaylistService,
    private location: Location,
    private genericModalService: GenericModalService,
  ) {
    this.onTableFilter = debounce(this.onTableFilter, 300);
    this.configInit();
    this.subscribeChangedTags();
    this.subscribePlaylistChanges();
    this.routerSubscribe(true);
    this.routerSubscribe(false);
    this.genericTagsUpdate();
    this.updateHideOption();
  }

  updateHideOption() {
    this.updatePlaylistMenuCollapse$ = this.playlistService.playlistMenuCollapsed.subscribe({
      next: (data: any) => {
        if (data.searchSectionAction) {
          this.reloadIgnoreTags(true);
        }
      },
    });
  }

  subscribePlaylistChanges() {
    this.updatePlaylist$ = this.playlistsService.updatePlaylistEvent.subscribe({
      next: (playlist) => {
        const existingPlaylist = this.tableTabs[0]?.dataTable?.find(
          (playlistList) => playlistList._id === playlist._id,
        );
        if (existingPlaylist) {
          if (
            existingPlaylist.editorial !== playlist.editorial ||
            existingPlaylist.groups !== playlist.groups ||
            existingPlaylist.thumbUrl !== playlist.thumbUrl
          ) {
            setTimeout(() => {
              this.getPlaylistElastic(1);
            });
          } else {
            existingPlaylist.name = playlist?.name || existingPlaylist.name;
            existingPlaylist.totalSongs = playlist.totalSongs ?? existingPlaylist.totalSongs;
            this.cd.detectChanges();
          }
        } else if (playlist.justCreated) {
          this.navigationPlaylistId = playlist;
          setTimeout(() => {
            this.getPlaylistElastic(1);
          });
        }
      },
    });
  }

  subscribeChangedTags() {
    this.tagAdded$ = this.searchTagsService.tagAdded.subscribe({
      next: (tag) => {
        if (tag?.category !== 'playlist') {
          if (this.playlistService.getTags() !== this.oldParams && !this.ignoreTags) {
            this.loading = true;
            this.cd.detectChanges();
            if (this.tableTabs.length > 0) this.getPlaylistElastic(1);
          }
        }
      },
    });

    this.tagRemoved$ = this.searchTagsService.tagRemoved.subscribe({
      next: (tag) => {
        if (tag?.category !== 'playlist') {
          if (this.playlistService.getTags() !== this.oldParams && !this.ignoreTags) {
            this.loading = true;
            this.cd.detectChanges();
            if (this.tableTabs.length > 0) this.getPlaylistElastic(1);
          }
        }
      },
    });
  }

  public closePLsection() {
    this.playerService.showPlaylistMenu.next(false);
    this.trackingService.track(
      'Playlist menu closed with arrow-left icon',
      {},
      {
        event_action: 'Playlist menu closed with arrow-left icon',
        event_type: 'Button Click',
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editorialSearchParam) {
      if (
        changes.editorialSearchParam?.currentValue &&
        changes.editorialSearchParam?.currentValue !== ''
      ) {
        this.loading = true;
        this.editorial = true;
        this.getPlaylistElastic(1);
      } else if (changes.editorialSearchParam?.previousValue) {
        this.getPlaylistElastic(1);
      }
    }
    if (changes.goToEditorial?.currentValue === true) {
      this.editorial = true;
      this.getPlaylistElastic(1);
    }
  }

  ngOnDestroy(): void {
    this.playerService.showPlaylistMenu.next(false);
    this.routerSubscribe$.forEach((sub) => sub.unsubscribe());
    this.tagAdded$?.unsubscribe();
    this.tagRemoved$?.unsubscribe();
    this.updatePlaylist$?.unsubscribe();
    this.updatePlaylistFromData$?.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.genericTagUpdated$?.unsubscribe();
    this.updatePlaylistMenuCollapse$?.unsubscribe();
    this.playlistService.updatePlaylist.next({}); // This prevents to open a PL detail to the left side
    console.log('destruyendo playlist menu');
  }

  ngOnInit() {
    const { songFeaturedIn, name } = this.router.routerState.snapshot.root.queryParams;
    this.slugOrId = this.currentUrl?.split('/')[3]?.split('?')
      ? this.currentUrl?.split('/')[3]?.split('?')[0]
      : this.currentUrl?.split('/')[3]?.split('?');
    if (songFeaturedIn) {
      this.filterSong = { songFeaturedIn, name };
    }
    //This is to apply filters from the url for mobile
    if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
      if (this.currentUrl.includes('pls=')) {
        this.editorial = true;
        const editorialParam = this.currentUrl
          .split('?')[1]
          .split('&')
          .find((param) => param.includes('pls='));
        this.editorialSearchParam = editorialParam.split('=')[1];
      }

      const tagParams = this.router.routerState.snapshot.root.queryParams?.tags;
      const tagsToSearch = tagParams?.split(',') || [];
      const tagsObject = tagsToSearch.map((tag) => ({
        slug: tag.includes('!') ? tag.split('!')[1]?.trim() : tag,
        negative: tag.includes('!'),
      }));
      tagsObject.forEach((tag, index) => {
        if (tag) {
          const isNegative = tagsObject[index].negative;
          tag.selectedNegative = isNegative;
          tag.selected = !isNegative;
          tag.negative = isNegative;
          this.searchTagsService.addTag(tag);
        }
      });
      this.ignoreTags = false;
    }
    this.initTable();
    this.getPlaylistElastic(1);
    this.updatePlaylistFromData();
  }

  configInit() {
    this.sessionSettings = this.authenticationService.getUserSessionSettings();
    if (this.sessionSettings && this.currentUser) {
      this.displayTeamPlaylist = this.sessionSettings.teamPlaylist || false;
      this.editorial = this.sessionSettings.editorial ?? true;
      this.ignoreTags = this.sessionSettings.ignoreTags ?? false;
      if (this.sessionSettings?.plFilterLabel) {
        this.plTypingOptions.forEach((option) => {
          if (option.label === this.sessionSettings.plFilterLabel) {
            this.plTypingType = option.query;
            this.plFilterLabel = option.label;
          }
          option.selected = option.label == this.sessionSettings.plFilterLabel;
        });
      }
    }
  }

  mobileRedirect() {
    if (this.router.url.split('/')[2] && this.router.url.split('/')[2] !== '') {
      const navigationObject = {
        _id: !this.editorial ? this.router.url.split('/')[2] : null,
        slug: this.editorial ? this.router.url.split('/')[2] : null,
      };
      this.navigationPlaylistId = navigationObject;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowHeight = window.innerHeight - this.heightSection;
  }

  onRowSelected(event) {
    const identifier = this.getPlaylistIdentifier(event.rowData);
    if (
      !(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) &&
      this.searchView
    ) {
      let params = clone(this.router.routerState.snapshot.root.queryParams);
      const route = this.router.url.includes('/edit-playlist') ? 'edit-playlist' : 'playlist';
      this.router.navigate([`/music-sync-licensing/${route}/${identifier}`], {
        queryParams: params,
      });
      if (!event?.rowData?.editorial) {
        this.trackingService.track(
          'User Playlist played',
          {
            playlist: event?.rowData,
          },
          {
            event_action: 'User Playlist played',
            event_type: 'Playlist Play',
            event_value: event?.rowData?._id || event?.rowData?.slug,
          },
        );
      }
    } else {
      this.playlistSelected.emit(identifier);
    }
  }

  onTableFilter() {
    this.editorialSearchParam = null;
    this.deleteEditorialParam.emit(null);
    this.tableTabs[0].loading = true;
    this.tableTabs[0].dataTable = [];
    this.cd.detectChanges();
    this.getPlaylistElastic(1);
  }

  onTableScroll() {
    if (this.tableTabs[0].settings.paging.page < this.tableTabs[0].settings.paging.totalPages) {
      this.getPlaylistElastic(this.tableTabs[0].settings.paging.page + 1);
    }
  }

  showCreatePlaylistModal() {
    this.newPlaylistModal.showModal();
  }

  onCreatePlaylistSuccess(event) {
    this.toastr.success(`Playlist ${event.data.name} created successfully`, '');
    setTimeout(() => {
      this.getPlaylistElastic(1);
      if (!(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet())) {
        this.navigationPlaylistId = event.data;
      }
    }, 500);
  }

  onCreatePlaylistError(event) {
    this.toastr.error(event, 'There was an error creating your playlist');
    Sentry.captureException(
      new Error(
        `There was an error creating your playlist. API error: status ${event.status} - ${
          event?.error?.message || event.message || event
        }`,
      ),
    );
  }

  onRowRemoved(event) {
    this.genericModalService.showModal(
      {
        title: 'Remove playlist',
        message: 'Are you sure you want to remove the playlist "' + event.tableRow.name + '"?',
        auxMessage: 'By removing the playlist you will remove all the songs included',
        cssClass: 'fa fa-exclamation-triangle',
        size: 'modal-md',
        btns: [
          {
            status: true,
            data: { playlistId: event.tableRow._id, playlistName: event.tableRow.name },
            cssClass: 'primary-btn',
            title: 'Accept',
          },
          { kind: 'close', title: 'Cancel', cssClass: 'cancel-btn' },
        ],
      },
      this.onModalClosed.bind(this),
    );
    this.trackingService.track(
      'Remove playlist modal',
      {
        action: 'Remove playlist modal',
        kind: 'Modal Open',
        artistSlug: event.tableRow,
      },
      {
        event_action: 'Remove playlist modal opened',
        event_type: 'Modal Opened',
        event_value: event.tableRow?._id,
      },
    );
  }

  onModalClosed(event) {
    if (event && event.data) {
      this.playlistsService.delete(event.data.playlistId).subscribe({
        next: () => {
          this.playlistsCount.next(this.playlistsCount.getValue() - 1);
          this.loading = true;
          this.cd.detectChanges();
          setTimeout(() => {
            this.getPlaylistElastic(1);
            if (this.selectedPlaylistId === event.data.playlistId) {
              this.playlistSelected.emit('empty');
            }
          }, 500);
          this.trackingService.track(
            'Playlist Removed from playlist menu',
            {
              action: 'Playlist Removed from playlist menu',
              kind: 'Playlist Removed',
              playlistData: event.data,
            },
            {
              event_action: 'Playlist Removed from playlist menu',
              event_type: 'Playlist Removed',
              event_value: event.data.playlistId,
            },
          );
        },
        error: (err) => {
          this.toastr.error(
            err?.error?.message,
            'There was an error removing the playlist ' + event.data.playlistName,
          );
          Sentry.captureException(
            new Error(
              `There was an error removing the playlist. API error: status ${err.status} - ${
                err?.error?.message || err?.message
              }`,
            ),
          );
        },
      });
    }
  }

  private showStatusSong(song): boolean {
    return (
      !!song?.notes ||
      song?.tierHasWarning ||
      song?.publicAccess === false ||
      song?.isOneStop == false ||
      song?.releaseStatus === 'unreleased' ||
      song?.customizable === 'no' ||
      song?.stemsAvailable === 'no'
    );
  }

  private trackUpdatePlaylist(playlist: any, track?: any, data?: any) {
    const eventParams: any = {
      song: track?.name || track?.fileName || track?.songName,
      artists:
        track?.artists && track?.artists?.length > 0
          ? map(track?.artists, 'name').join(', ')
          : track?.artistName,
      playlistId: playlist?._id,
      playlistName: playlist?.name,
    };
    if (data.indexSuggestion) {
      eventParams.suggestionIndex = data.indexSuggestion;
      eventParams.profile = data.profile;
    }
    this.trackingService.track('Track added successfully to playlist', eventParams, {
      event_action: 'Track added to playlist',
      event_type: 'Song',
      event_value: track?.name || track?.songName || track?.fileName,
      element_type: 'Playlist',
      element_value: playlist?.id,
      element_id: playlist?.itemName,
    });
  }

  private updatePlaylistFromData() {
    this.updatePlaylistFromData$ = this.playlistService.updatePlaylist.subscribe({
      next: (playlist: any) => {
        if (playlist?._id) {
          this.updateSongsPlaylistMenu(playlist);
        }
      },
    });
  }

  private updateSongsPlaylistMenu(playlist: any) {
    if (!!!this.navigationPlaylistId) {
      playlist.totalSongs = playlist.totalSongs + 1;
    }
    console.log('>>>playlist', playlist);
    this.playlistsService.updatePlaylistEvent.next(playlist);
  }

  public addSongToPlaylist(data) {
    const user = this.authenticationService.getCurrentUser();
    if (data.hasOwnProperty('indexSuggestion')) {
      this.playlistDetail.recommendedSongs[data.indexSuggestion].load = true;
    }
    const playlist = data.event.tableRow;
    this.playlistService.setLastPlaylist({
      id: playlist._id,
      type: playlist.ownerUid === user.uid ? 'my-playlist' : 'team',
    });
    const track = cloneDeep(data.song);
    if (!track.hasOwnProperty('uri')) {
      const tenantId = user.tenantId;
      track.uri = `${tenantId}:core:songs:${track.soId}/${track._id}`;
    }
    const body = track.uri ? { uris: [track.uri] } : { trackPayload: true, tracks: [track] };
    this.playlistsService
      .addTracks(playlist._id, body)
      .pipe(
        finalize(() => {
          if (data.hasOwnProperty('indexSuggestion')) {
            this.playlistDetail.recommendedSongs[data.indexSuggestion].load = false;
          }
        }),
      )
      .subscribe({
        next: (res) => {
          if (res.data.nModified === 1 || res.data.modifiedCount === 1) {
            this.toastr.success('Track added successfully', '');
            this.trackUpdatePlaylist(playlist, track, data);
            this.updateSongsPlaylistMenu(playlist);
          } else {
            this.toastr.warning('Track already in playlist', '');
          }
        },
        error: (err) => {
          this.toastr.error(
            err?.error?.message,
            'There was an error adding your song to the playlist',
          );
          this.trackingService.track('Error adding the song to the playlist', {
            song: track,
            playlistId: playlist?._id,
            error: err,
          });
          Sentry.captureException(
            new Error(
              `There was an error adding your song to the playlist. API error: status ${
                err.status
              } - ${err?.error?.message || err?.message}`,
            ),
          );
        },
      });
  }

  onRowMouseUp(event) {
    if (this.playerService.draggedSong && !this.playerService.draggedSong.lelftToRight) {
      const playlist = event.tableRow;
      const canEdit = this.commonService.canEditPlaylist(playlist);
      if (!canEdit) {
        this.toastr.warning("You don't have permission to edit this playlist", 'Permission Denied');
        return;
      }
      const song = { ...this.playerService.draggedSong };
      if (
        this.showStatusSong(song.versions?.match) &&
        this.commonService.isAllowed(['*', 'ListTiers'])
      ) {
        this.songStatusModal.tracks = [song.versions?.match];
        this.songStatusModal.data = { event, song: song.versions?.match };
        this.songStatusModal.showModal();
      } else {
        this.addSongToPlaylist({ event, song: song.versions?.match });
      }
    }
  }

  onPlaylistDetailsPlay(event) {
    const identifier = this.getPlaylistIdentifier(event);
    if (this.searchView) {
      let params = clone(this.router.routerState.snapshot.root.queryParams);
      const route = this.router.url.includes('/edit-playlist') ? 'edit-playlist' : 'playlist';
      this.router.navigate([`/music-sync-licensing/${route}/${identifier}`], {
        queryParams: params,
      });
    } else {
      this.playlistSelected.emit(identifier);
    }
  }

  displayOrHideTeamPlaylist(value: boolean) {
    if (this.currentUser) {
      if (!this.sessionSettings) {
        this.sessionSettings = {};
      }
      this.sessionSettings.editorial = false;
      this.sessionSettings.teamPlaylist = value;
      localStorage.setItem('SOSTEREO.sessionSettings', JSON.stringify(this.sessionSettings));
    }
    this.loading = true;
    this.editorial = false;
    this.displayTeamPlaylist = value;
    this.inputValue = '';
    this.getPlaylistElastic(1);
  }

  displayEditorialPlaylist(value: boolean) {
    this.trackingService.track(
      'Playlist Tab changed',
      {
        tab: value ? 'Editorial Playlists' : 'User Playlists',
      },
      {
        event_action: 'Playlist Tab changed',
        event_type: 'Playlist Tab Click',
        event_value: value ? 'Editorial Playlists' : 'User Playlists',
      },
    );
    if (this.currentUser) {
      if (!this.sessionSettings) {
        this.sessionSettings = {};
      }
      this.sessionSettings.editorial = value;
      localStorage.setItem('SOSTEREO.sessionSettings', JSON.stringify(this.sessionSettings));
    }

    this.editorial = value;
    this.loading = true;
    this.inputValue = '';
    this.editorialSearchParam = null;
    if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
      this.searchTagsService.selectedTags = [];
      this.location.go('/my-playlists');
    }
    this.deleteEditorialParam.emit(null);
    this.getPlaylistElastic(1);
  }

  removeSongFilter() {
    this.tableTabs[0].settings.paging.page = 1;
    this.tableTabs[0].loading = true;
  }

  onDropdownSelected($event) {
    if ($event.option.value === 'delete') {
      this.onRowRemoved($event);
    } else {
      this.duplicatePlaylist($event);
    }
  }

  duplicatePlaylist($event) {
    const playlist = { id: $event.tableRow._id, name: `${$event.tableRow.name} (Copy)` };
    this.copyPlaylistModal.showModal(playlist);
  }

  onCopyPlaylistSuccess() {
    this.loading = true;
    this.getPlaylistElastic(1);
  }

  initTable() {
    const tableConfig = playlistMenuTable(
      {},
      this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet(),
    );
    this.tableTabs.push({
      tableId: 'playlistMenuTable',
      title: 'My Playlists',
      settings: tableConfig,
      dataTable: [],
      loading: false,
    });
  }

  mapPlaylist({ items, paging }) {
    paging.totalPages = Math.ceil(paging.totalItems / paging.limit);
    const newItems = items.map((item) => {
      const slug = item._source.slug?.split('-');
      const ownerName =
        item._source.ownerUid === this.currentUser?.uid
          ? this.currentUser.nickname
          : slug?.[slug.length - 1] || 'Unknown';
      return { _id: item._id, ownerName, ...item._source };
    });
    return { items: newItems, paging };
  }

  getParams(page: number) {
    let ignoreCurrentTags = !this.plLibrary ? this.ignoreTags : this.hideTags;
    const params = this.playlistService.getParams(
      page,
      this.editorial,
      this.currentUser,
      ignoreCurrentTags,
      this.editorialSearchParam && this.editorialSearchParam !== ''
        ? this.editorialSearchParam
        : this.inputValue,
      this.displayTeamPlaylist,
      this.filterSong,
      this.plTypingType,
    );
    this.oldParams = params.tags;
    return params;
  }

  getPlaylistElastic(page: number = 1) {
    if (this.editorial || !!this.currentUser) {
      const params = this.getParams(page);
      if (this.tableTabs[0]) {
        this.tableTabs[0].loading = true;
      }
      this.searchService
        .getSuggestions(params)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => {
            this.loading = false;
            this.tableTabs[0].loading = false;
            if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
              setTimeout(() => {
                this.windowHeight = window.innerHeight - this.heightSection;
                this.cd.detectChanges();
              }, 1000);
            }
            this.cd.detectChanges();
          }),
        )
        .subscribe({
          next: (res) => {
            console.log('navplid', this.navigationPlaylistId);
            this.noResults = false;
            const newPlaylist = this.mapPlaylist(res.data.playlists);
            if (page === 1) {
              const { id } = this.route.snapshot.queryParams;
              this.tableTabs[0].dataTable = newPlaylist.items;
              // params.q !== '*~' validation is so the filter input doesn't hide if user types in it but there is no items retrieved
              this.noResults =
                this.tableTabs[0]?.dataTable.length > 0 ? false : params.q !== '*~' ? false : true;
              if (
                !id &&
                !(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet())
              ) {
                const identifier = this.getPlaylistIdentifier(newPlaylist.items?.[0]);
                this.playlistSelected.emit(identifier || 'empty');
              }
              if (
                (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) &&
                this.navigationPlaylistId === undefined
              ) {
                this.mobileRedirect();
              }
            } else {
              this.tableTabs[0].dataTable = this.tableTabs[0].dataTable.concat(newPlaylist.items);
            }
            this.tableTabs[0].settings.paging = newPlaylist.paging;

            this.cd.detectChanges();
          },
          error: (err) => {
            this.toastr.error(err?.error?.message, 'There was an error loading the playlists menu');
            console.error('Error to get playlistsStereoSpacesService >>>> ', err);
            Sentry.captureException(
              new Error(
                `There was an error loading the playlists menu. API error: status ${err.status} - ${
                  err?.error?.message || err?.message
                }`,
              ),
            );
          },
        });
    } else {
      this.noResults = true;
      this.tableTabs[0].dataTable = [];
      this.tableTabs[0].settings.paging = {};
      this.loading = false;
      if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
        setTimeout(() => {
          this.windowHeight = window.innerHeight - this.heightSection;
          this.cd.detectChanges();
        }, 1000);
      }
    }
  }

  public rowAction({ rowData }) {
    if (!rowData?.editorial) {
      this.trackingService.track(
        'User Playlist selected',
        {
          playlist: rowData,
        },
        {
          event_action: 'User Playlist selected',
          event_type: 'Playlist Click',
          event_value: rowData?._id || rowData?.slug,
        },
      );
    }
    this.navigationPlaylistId = rowData;
    if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
      const url = this.router
        .createUrlTree([`/my-playlists/${this.editorial ? rowData.slug : rowData._id}`])
        .toString();
      this.location.go(url);
    }
  }

  public emitEditPlaylist(playlist) {
    this.editPlaylist.emit(playlist);
  }

  public openSearchInput() {
    this.openSearch = !this.openSearch;
    if (this.openSearch) {
      document.getElementById('playlist-search-menu').focus();
    }
  }

  public reloadIgnoreTags(ignoreCollapse?) {
    this.loading = true;
    this.ignoreTags = !this.ignoreTags;
    this.editorialSearchParam = null;
    this.deleteEditorialParam.emit(null);

    if (this.plLibrary) {
      this.hideTags = !this.hideTags;
      if (!ignoreCollapse) {
        this.playlistService.playlistMenuCollapsed.next({ isCollapsed: this.hideTags });
      }
    }
    if (!ignoreCollapse) {
      this.trackingService.track(
        'Playlists menu Filter/Show Button click',
        {
          buttonText: this.ignoreTags || this.hideTags ? 'Apply filters' : 'Show All',
          tags: this.searchTagsService.selectedTags,
        },
        {
          event_action: 'Playlists menu Filter/Show Button click',
          event_type: 'Button Click',
          event_value: this.ignoreTags || this.hideTags ? 'Apply filters' : 'Show All',
        },
      );
    }
    this.getPlaylistElastic(1);
    if (!this.sessionSettings) {
      this.sessionSettings = {};
    }
    this.sessionSettings.ignoreTags = this.ignoreTags;
    localStorage.setItem('SOSTEREO.sessionSettings', JSON.stringify(this.sessionSettings));
  }

  public removeFilterSong() {
    this.filterSong = null;
    const params = this.router.routerState.root.snapshot.queryParams;
    const url = this.router.url.split('?')[0];

    this.router.navigate([url], { queryParams: { ...params, songFeaturedIn: null, name: null } });
    this.getPlaylistElastic(1);
  }

  public signUp() {
    const url = window.location.pathname + window.location.search;
    this.router.navigate(['/signup'], { queryParams: { returnUrl: url } });
  }

  private routerSubscribe(isNavigationEnd: boolean) {
    this.routerSubscribe$.push(
      this.router.events
        .pipe(
          filter((event) =>
            isNavigationEnd ? event instanceof NavigationEnd : event instanceof ChildActivationEnd,
          ),
          throttleTime(1000),
        )
        .subscribe((event) => {
          if (event instanceof ChildActivationEnd) {
            const { songFeaturedIn, name } = event.snapshot.queryParams;
            if (songFeaturedIn && !this.loading) {
              this.filterSong = { songFeaturedIn, name };
              this.loading = true;
              this.getPlaylistElastic(1);
            }
          }
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.url;
            this.slugOrId = this.currentUrl?.split('/')[3]?.split('?')
              ? this.currentUrl?.split('/')[3]?.split('?')[0]
              : this.currentUrl?.split('/')[3];
            this.cd.detectChanges();
            // This is to reset view when user has PL section open and wants to go to edit-playlist
            if (
              !this.router
                .getCurrentNavigation()
                .previousNavigation?.finalUrl?.toString()
                ?.includes('edit-playlist') &&
              event.url.includes('edit-playlist')
            ) {
              this.navigationPlaylistId = '';
              this.getPlaylistElastic(1);
            }
            if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
              if (this.currentUrl.includes('pls=')) {
                this.editorial = true;
                const editorialParam = this.currentUrl
                  .split('?')
                  .find((param) => param.includes('pls='));
                this.editorialSearchParam = editorialParam.split('=')[1];
              }
            }
          }
        }),
    );
  }

  private getPlaylistIdentifier(playlist: any) {
    return this.isAdmin ? playlist?._id || playlist?.playlistId : playlist?.slug;
  }

  goBackToMenu() {
    this.navigationPlaylistId = '';
    if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
      const baseUrl = this.currentUrl.split('?');
      if (baseUrl[1]) {
        this.location.go(`/my-playlists?${baseUrl[1]}`);
      } else {
        this.location.go('/my-playlists');
      }
    }
  }

  public genericTagsUpdate() {
    this.genericTagUpdated$ = this.searchTagsService.genericTagUpdated.subscribe((tags) => {
      let newTags = tags || '';
      if (tags) {
        this.playlistService.tagOptions.forEach((t) => {
          newTags = tags.replaceAll(t, '').replaceAll('!', '').replaceAll(',', ', ');
        });
      }
      if (newTags !== this.currentGenericTags) {
        this.currentGenericTags = newTags || '';
        if (!this.ignoreTags || (this.plLibrary && !this.hideTags)) {
          this.getPlaylistElastic(1);
        }
      }
    });
  }

  public onMouseOver() {
    this.showBackOption = true;
    this.displayHideOption = !this.hideOptionToCollapse;
  }

  public onMouseLeave() {
    this.showBackOption = false;
    this.displayHideOption = false;
  }

  public addSuggestionSong($event: any) {
    if (this.showStatusSong($event?.song) && this.commonService.isAllowed(['*', 'ListTiers'])) {
      this.songStatusModal.tracks = [$event?.song];
      this.songStatusModal.data = {
        event: { tableRow: { ...$event.event.tableRow } },
        indexSuggestion: $event.indexSuggestion,
        song: $event?.song,
      };
      this.songStatusModal.showModal();
    } else {
      this.addSongToPlaylist($event);
    }
  }

  public selectPlFilterType(selectedId) {
    this.plTypingOptions.forEach((option) => {
      if (option.id === selectedId) {
        this.plTypingType = option.query;
        this.plFilterLabel = option.label;
      }
      option.selected = option.id === selectedId;
    });
    if (this.currentUser) {
      if (!this.sessionSettings) {
        this.sessionSettings = {};
      }
      this.sessionSettings.plFilterLabel = this.plFilterLabel;
      localStorage.setItem('SOSTEREO.sessionSettings', JSON.stringify(this.sessionSettings));
    }
    this.getPlaylistElastic(1);
  }
}
