<div
  class="row blue-panel pl-0 pr-0 ml-0 mr-0"
  id="menuTableContainer"
  [hidden]="!!navigationPlaylistId"
  (mouseover)="displayHideOption = !hideOptionToCollapse"
  (mouseleave)="displayHideOption = false"
>
  <mat-menu #plFilterOptions="matMenu" class="menu-blue">
    <button
      mat-menu-item
      *ngFor="let option of plTypingOptions"
      (click)="selectPlFilterType(option.id)"
    >
      {{ option.label
      }}<span
        *ngIf="option.selected"
        class="material-icons-outlined ml-1.5"
        style="vertical-align: -2px"
        [attr.data-icon]="'check'"
      >
      </span>
    </button>
  </mat-menu>
  <div class="playlist-menu mt-[10px]">
    <div class="row tabs ml-0 mr-0 text-sm">
      <div class="col-sm-6 col-xs-6 pl-0 pr-0" (click)="displayEditorialPlaylist(true)">
        <h4 class="pl-0 pt-[5px] text-center text-[14px]">Editorial Playlist</h4>
      </div>
      <div class="col-sm-6 col-xs-6 pl-0 pr-0 flex justify-around" style="gap: 5px">
        <div
          *ngIf="currentUser?.groups?.length > 0"
          id="dropdown"
          class="dropdown flex items-center"
          style="position: absolute; left: 15px; bottom: 10px"
        >
          <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i
              class="material-icons"
              [attr.data-icon]="displayTeamPlaylist ? 'group' : 'group_off'"
              style="font-size: 16px; position: relative; top: 5px; margin-right: 8px"
            ></i>
            <i
              class="material-icons-outlined"
              style="position: relative; top: 5px; right: 9px; font-size: 15px"
              [attr.data-icon]="'expand_more'"
            >
            </i>
          </span>
          <ul
            id="dropdown-basic"
            class="dropdown-menu"
            [ngClass]="{
              'dropdown-mobile':
                deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
            }"
          >
            <li aria-hidden="true" (click)="displayOrHideTeamPlaylist(false)">
              <a class="pl-2.5"
                ><p class="mb-0" [ngClass]="{ 'pl-4': displayTeamPlaylist }">
                  <i class="fa fa-check" *ngIf="!displayTeamPlaylist"></i>
                  My playlist
                </p></a
              >
            </li>
            <li aria-hidden="true" (click)="displayOrHideTeamPlaylist(true)">
              <a class="pl-2.5"
                ><p class="mb-0" [ngClass]="{ 'pl-4': !displayTeamPlaylist }">
                  <i class="fa fa-check" *ngIf="displayTeamPlaylist"></i>
                  Team playlists
                </p>
              </a>
            </li>
          </ul>
        </div>
        <h4
          class="pl-[15px] pt-[4px] pb-[8px] text-center text-[14px]"
          (click)="displayEditorialPlaylist(false)"
        >
          {{ !displayTeamPlaylist ? 'My Playlists' : 'Team Playlists' }}
        </h4>
      </div>
    </div>
    <div
      *ngIf="
        !(deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) && displayHideOption
      "
      class="close-pl-section center-flex"
      (click)="closePLsection()"
    >
      <span class="material-icons-outlined" [attr.data-icon]="'chevron_left'"> </span>
    </div>
    <div class="row mr-0 ml-0">
      <div class="col-sm-6 col-xs-6" [ngClass]="editorial ? 'activeTab' : 'tab'"></div>
      <div class="col-sm-6 col-xs-6" [ngClass]="!editorial ? 'activeTab' : 'tab'"></div>
    </div>
  </div>
  <div *ngIf="!loading" [@inOutAnimation]>
    <div class="col-sm-12 col-xs-12 pl-4 filter-container mt-2.5">
      <div
        (click)="removeFilterSong()"
        *ngIf="filterSong"
        class="filterElement flex col-xs-12 pr-0 justify-end"
      >
        <span class="material-icons-outlined mr-1" [attr.data-icon]="'search_off'"> </span
        >{{ filterSong.name || filterSong.songFeaturedIn }}
      </div>
      <div
        class="col-xs-12 pl-0 pr-0 flex mt-2.5 mb-2.5"
        [ngStyle]="{ gap: !editorial ? '10px' : '0px' }"
      >
        <div
          class="flex items-center add-playlist"
          style="cursor: pointer"
          (click)="currentUser ? showCreatePlaylistModal() : signUp()"
        >
          <span
            *ngIf="!editorial"
            class="material-icons-outlined"
            [attr.data-icon]="'add'"
            matTooltip="Create Playlist"
            matTooltipClass="bg-tooltip"
            [matTooltipPosition]="'above'"
          >
          </span>
          <span class="label-hover" style="font-size: 14px; font-weight: 400">New playlist</span>
        </div>

        <div
          *ngIf="!editorial && !noResults"
          class="flex"
          style="cursor: pointer; flex-grow: 1; position: relative"
        >
          <span
            class="material-icons-outlined"
            [attr.data-icon]="'filter_list'"
            matTooltip="Search"
            matTooltipClass="bg-tooltip"
            [matTooltipPosition]="'above'"
            style="position: absolute; font-size: 20px; top: 4px"
            (click)="openSearchInput()"
          >
          </span>
          <input
            id="playlist-search-menu"
            type="text"
            class="pr-0 mb-0 mr-0 inputHidden"
            [placeholder]="openSearch ? 'Search' : ''"
            [ngClass]="{ active: openSearch }"
            [(ngModel)]="inputValue"
            (input)="onTableFilter()"
            (keydown)="$event.stopPropagation()"
          />
          <div *ngIf="openSearch" class="pl-filter-type">
            <span
              class="material-icons-outlined"
              [attr.data-icon]="'tune'"
              matTooltip="Type"
              matTooltipClass="bg-tooltip"
              [matTooltipPosition]="'above'"
              mat-button
              [matMenuTriggerFor]="plFilterOptions"
            >
            </span>
          </div>
        </div>
      </div>
      <div class="playlist-info">
        <div class="pl-detail">
          {{ tableTabs[0]?.settings?.paging?.totalItems || 0 }} -
          <span *ngIf="editorial">
            <span *ngIf="plLibrary && !editorialSearchParam">{{
              hideTags ? 'Curated playlists' : 'Playlists match your filters'
            }}</span>
            <span *ngIf="!plLibrary && !editorialSearchParam">{{
              ignoreTags || searchTagsService.selectedTags?.length === 0
                ? 'Curated playlists'
                : 'Playlists match your filters'
            }}</span>
            <span *ngIf="editorialSearchParam"
              >Playlists match your search {{ '(' + editorialSearchParam + ')' }}</span
            >
          </span>
          <span *ngIf="!editorial">
            {{ displayTeamPlaylist ? 'Team p' : 'P' }}laylists
            <span *ngIf="plLibrary">{{ hideTags ? '' : ' match your filters' }}</span>
            <span *ngIf="!plLibrary">{{
              ignoreTags || searchTagsService.selectedTags?.length === 0
                ? ''
                : ' match your filters'
            }}</span>
          </span>
        </div>
        <div
          *ngIf="searchTagsService.selectedTags?.length > 0 || plLibrary || editorialSearchParam"
          class="action-btn hover:underline"
          (click)="reloadIgnoreTags()"
        >
          <span *ngIf="!plLibrary && !editorialSearchParam">{{
            ignoreTags ? 'Apply filters' : 'Show All'
          }}</span>
          <span *ngIf="plLibrary && !editorialSearchParam">{{
            hideTags ? 'Apply filters' : 'Show All'
          }}</span>
          <span *ngIf="editorialSearchParam">Show All</span>
        </div>
      </div>
    </div>
    <div
      class="col-sm-12 col-xs-12 pl-0 pr-0"
      [ngClass]="{
        'center-data': noResults
      }"
    >
      <div *ngIf="!editorial && !noResults">
        <app-table
          (rowAction)="onRowSelected($event)"
          (rowRemoved)="onRowRemoved($event)"
          (tableScroll)="onTableScroll()"
          (rowMouseUp)="onRowMouseUp($event)"
          (dropdownSelected)="onDropdownSelected($event)"
          (rowSelected)="rowAction($event)"
          [tabs]="tableTabs"
          [height]="
            windowHeight -
            (playerWidgetService?.showPlayer ? 80 : 20) -
            (deviceDetectorService.isMobile() || deviceDetectorService.isTablet() ? 50 : 0)
          "
          [completePage]="true"
          [hideHeader]="true"
          [noMTop]="true"
          [customPR]="'pr-0'"
          [slugOrId]="slugOrId"
        >
        </app-table>
      </div>
      <div *ngIf="noResults" style="text-align: center">
        <p class="mb-1 text-[16px] text-primary font-semibold">No results to show</p>
        <p *ngIf="!editorial" class="pl-5 pr-5 text-primary">
          You haven't created any playlists yet
          {{
            !currentUser || playlistsCount.getValue() === 0
              ? ''
              : 'that match the selected filters '
          }}
          <span
            *ngIf="currentGenericTags && playlistsCount.getValue() > 0"
            class="text-primary font-semibold"
            >{{ currentGenericTags }}</span
          >
        </p>
        <p *ngIf="editorial" class="pl-5 pr-5 text-primary">
          There are no playlists that match the selected filters
          <span *ngIf="currentGenericTags" class="font-semibold">{{ currentGenericTags }}</span>
        </p>
        <div class="center-flex">
          <div
            *ngIf="editorial || playlistsCount.getValue() > 0"
            class="flex items-center add-playlist mt-2.5 add-pl-btn"
            (click)="reloadIgnoreTags()"
          >
            <span class="label-hover pl-1 text-[14px] cursor-pointer">Show All</span>
          </div>
          <div
            *ngIf="!editorial && playlistsCount.getValue() === 0"
            class="flex items-center add-playlist mt-2.5 add-pl-btn"
            style="cursor: pointer"
            (click)="currentUser ? showCreatePlaylistModal() : signUp()"
          >
            <span
              class="material-icons-outlined"
              [attr.data-icon]="'add'"
              matTooltip="Create Playlist"
              matTooltipClass="bg-tooltip"
              [matTooltipPosition]="'above'"
            >
            </span>
            <span class="label-hover" style="font-size: 14px; font-weight: 400">New playlist</span>
          </div>
        </div>
      </div>
      <app-playlists-editorial
        *ngIf="editorial"
        [playlists]="tableTabs?.[0]?.dataTable"
        [loading]="tableTabs[0]?.loading && loading"
        (navigateCard)="onRowSelected($event)"
        (selectedCard)="rowAction($event)"
        (contentScroll)="onTableScroll()"
      >
      </app-playlists-editorial>
    </div>
  </div>
  <div class="loader" align="center" *ngIf="loading">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<div
  class="row blue-panel pl-0 pr-0 ml-0 mr-0"
  id="detailPlaylist2"
  *ngIf="!!navigationPlaylistId"
  (mouseover)="
    !(deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) ? onMouseOver() : ''
  "
  (mouseleave)="
    !(deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) ? onMouseLeave() : ''
  "
>
  <div class="back-arrow-container">
    <p
      *ngIf="showBackOption"
      (click)="
        deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
          ? goBackToMenu()
          : (navigationPlaylistId = '')
      "
    >
      < {{ editorial ? 'Editorial' : 'My' }} Playlists
    </p>
  </div>
  <div
    *ngIf="
      !(deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) &&
      !currentUrl.includes('/edit-playlist')
    "
    class="close-pl-section center-flex close-pl-detail"
    (mouseover)="showClosePlsMenu = !showClosePlsMenu"
    (mouseleave)="showClosePlsMenu = true"
    (click)="closePLsection()"
  >
    <span class="material-icons-outlined" [attr.data-icon]="'chevron_left'"> </span>
    <span class="pl-detail-hover">Collapse</span>
  </div>
  <app-playlist-menu-detail
    #playlistDetail
    [playlistReference]="navigationPlaylistId"
    [editorial]="editorial"
    (duplicatePlaylistEvent)="duplicatePlaylist($event)"
    (editPlaylist)="emitEditPlaylist($event)"
    (rowMouseUp)="onRowMouseUp($event)"
    (playlistDetailsPlay)="onPlaylistDetailsPlay($event)"
    (addSuggestionSong)="addSuggestionSong($event)"
  ></app-playlist-menu-detail>
</div>
<app-new-playlist-modal
  #newPlaylistModalComponent
  (success)="onCreatePlaylistSuccess($event)"
  (error)="onCreatePlaylistError($event)"
></app-new-playlist-modal>
<app-copy-playlist-modal
  #copyPlaylistModal
  (success)="onCopyPlaylistSuccess()"
></app-copy-playlist-modal>
<app-song-status-modal #songStatusModalComponent (restrictionsAgreed)="addSongToPlaylist($event)">
</app-song-status-modal>
